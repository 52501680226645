import React from 'react'
import { graphql } from 'gatsby'
import { Location } from '@reach/router'
import get from 'lodash.get'
import LayoutEN from '../../layouts/en'
import Page from '../../views/Page'
import SEO from '../../components/seo'

const PageTemplate = ({ pageContext, location, data }) => {
  const page = get(data, 'prismic.page.body', [])
  const title = get(page, '[0].primary.titre_niveau_1[0].text', 'Page')

  return (
    <LayoutEN location={location} isDark={false}>
      <Location>
        {({ location }) => <SEO title={title} url={location.pathname} />}
      </Location>
      <div className="page">
        <Page blocks={page} />
      </div>
    </LayoutEN>
  )
}

export const query = graphql`
  query PageQueryEN($uid: String!) {
    prismic {
      page(uid: $uid, lang: "en-us") {
        _meta {
          id
          uid
        }
        body {
          __typename
          ... on PRISMIC_PageBodyText_structure {
            type
            label
            fields {
              paragraph
            }
            primary {
              titre_niveau_1
              titre_niveau_3
              titre_niveau_2
            }
          }
          ... on PRISMIC_PageBodyNuage_mots_cle {
            type
            label
            fields {
              keyword
            }
          }
          ... on PRISMIC_PageBodyImage {
            type
            label
            primary {
              image
            }
          }
          ... on PRISMIC_PageBodyMap {
            type
            label
            primary {
              address
            }
          }
        }
      }
    }
  }
`

export default PageTemplate
